import { useQuery } from '@tanstack/react-query';
import { api } from 'services/api';

const getCities = async (slug: string) => {
  return await api.get('/home/cities', { params: { slug } });
};

export const useCities = (slug: string, enabled = true, staleTime = 1000 * 60 * 5) => {
  return useQuery({
    queryKey: ['cities', slug],
    queryFn: () => getCities(slug),
    enabled,
    staleTime: staleTime, //5 minutes default
  });
};
