import { useRouter } from 'next/router';
import { FormEvent, useEffect, useState } from 'react';
import { TextField, Box } from '@solides/management-util-ui';
import { Button } from 'components/atoms';
import { removeEmojisInString } from 'utils/format';
import { FieldSingleLocation } from 'components/molecules';
import { useFetch } from 'hooks';
import * as styles from './styles';
import { ILocation } from 'components/molecules/FieldSingleLocation/types';
import { SearchBarProps } from './types';
import useStore from 'store';
import { useCities } from 'queries/cities';

const typeSearch = {
  inputLabel: 'Qual vaga você procura?',
  inputPlaceholder: 'Nome da vaga ou cargo',
  pathname: '/',
};

export const SearchBar = ({ className = '' }: SearchBarProps) => {
  const router = useRouter();
  const { company } = useStore(state => state.company);
  const { data: locationOptions } = useCities(company?.slug, !!company?.slug);

  const [title, setTitle] = useState('');
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const titleQuery = router.query?.title as string;
    const localsQuery = router.query?.locations as string;

    if (router.asPath.includes('?')) {
      titleQuery && setTitle(titleQuery);
      localsQuery && setLocations(localsQuery.split(','));
    } else {
      setTitle('');
      setLocations([]);
    }
  }, [router.query, router.asPath]);

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const initialParams = router.asPath.split('?')[1] || '';
    const urlParams = new URLSearchParams(initialParams);

    urlParams.set('page', '1');
    urlParams.set('locations', locations.filter(city => city !== 'Todos').join(','));
    urlParams.set('title', title);

    router.push({ pathname: typeSearch.pathname, query: urlParams.toString() });
  };

  const handleLocation = (location: ILocation) => setLocations([location.name]);

  return (
    <section className={className}>
      {company?.slogan && (
        <div className="absolute bottom-80 md:bottom-36 lg:bottom-36 bg-black bg-opacity-30 p-4 rounded-lg max-w-xs md:max-w-3xl lg:max-w-3xl">
          <div className="text-white text-xs md:text-md lg:text-md font-bold break-normal line-clamp-2">
            {company?.slogan}
          </div>
        </div>
      )}

      <Box className={styles.container}>
        <form className={styles.content} onSubmit={onSubmit}>
          <div className="w-full md:w-64 lg:w-96">
            <FieldSingleLocation
              label="Local"
              colorCompany={company?.colorCompany}
              colorTextCompany={company?.colorTextCompany}
              locations={locationOptions?.data || []}
              locationsSelected={locations}
              onChange={handleLocation}
            />
          </div>
          <div className={styles.field__title}>
            <TextField
              label={typeSearch.inputLabel}
              type="text"
              value={title}
              fieldSize="lg"
              name="vancacy"
              maxLength={50}
              id="title-field"
              data-cy="title-field-input"
              data-testid="title-field-input"
              iconLeft={{ icon: 'search' }}
              placeholder={typeSearch.inputPlaceholder}
              onChange={e => setTitle(removeEmojisInString(e.target.value))}
            />
          </div>

          <Button
            type="submit"
            text="Buscar vagas"
            className={`w-full md:w-44 justify-center bg-${
              company?.colorCompany ? `[${company?.colorCompany}]` : 'primary'
            } hover:bg-${company?.colorCompany ? `[${company?.colorCompany}]` : 'primary'}-700 flex text-${
              company?.colorTextCompany
                ? `[${company?.colorTextCompany == '#000' ? 'black' : 'white'}]`
                : 'black'
            }
            hover:text-${
              company?.colorTextCompany
                ? `[${company?.colorTextCompany == '#000' ? 'black' : 'white'}]`
                : 'black'
            }
             space-x-2 items-center border rounded-lg hover:rounded-lg hover:shadow-none disabled:shadow-none disabled:text-state-disabled/[0.8] hover:disabled:text-state-disabled/[0.8] transition duration-300 ease-in-out border-transparent disabled:bg-state-disabled-background/[0.24] hover:bg-opacity-90 hover:disabled:bg-state-disabled-background/[0.24] shadow-md-primary
             px-5 py-3 text-button-lg font-bold max-h-12 w-full justify-center`}
            data-cy="button-find"
          />
        </form>
      </Box>
    </section>
  );
};
