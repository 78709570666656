import { Vacancy } from 'types/vacancy';
import { ListEmptyVacancies, Pager } from 'components/atoms';
import { VacancyCard, VacancyCardSkeleton } from 'components/molecules';
import useStore from 'store/index';
import { VacancyQuantity } from 'components/molecules/VacancyQuantity';
import { useRouter } from 'next/router';
import { useMyApplications } from 'queries/applicant';

interface ListVacanciesProps {
  loading: boolean;
  vacancies: Vacancy[];
  pagination?: {
    totalPages: number;
    total: number;
    current: number;
    take: number;
  };
}

export const ListVacancies = ({ loading, vacancies, pagination }: ListVacanciesProps): JSX.Element => {
  const { isAuth } = useStore(store => store.session);
  const isApplicantsV3 = useStore(store => store.featuresToggle.isEnabled('applicantsV3'));

  const { data: myApplications } = useMyApplications(isApplicantsV3, isAuth); // TODO: voltar para v3 após testes
  const applications = myApplications?.data?.data?.map(({ id }) => id);

  const { query } = useRouter();

  if (loading || !vacancies) {
    return (
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 mt-4" role="list">
        {[1, 2, 3, 4, 5, 6].map(item => (
          <VacancyCardSkeleton key={item} />
        ))}
      </div>
    );
  }

  if (!vacancies.length && !query.title && !query.locations) {
    return <ListEmptyVacancies />;
  }

  if (!vacancies.length) {
    return null;
  }

  return (
    <>
      <VacancyQuantity
        paginationCurrent={pagination.current}
        paginationTake={pagination.take}
        paginationTotal={pagination.total}
      />
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 mt-4 mb-8" data-cy="list-vacancies">
        {vacancies.map(vacancy => (
          <li key={vacancy.id}>
            <VacancyCard vacancy={vacancy} applications={applications} />
          </li>
        ))}
      </ul>

      {pagination.totalPages > 1 && <Pager {...pagination} size={pagination.take} />}
    </>
  );
};
